import { util } from '@keystone/common';

const Routes = {
  Login: '/login',
  RequestPasswordReset: '/reset-password',
  ResetPassword: '/update-password',
  Error: '/error',
  Home: util.appendCustomerURL('/'),
  Users: util.appendCustomerURL('/users'),
  CreateUser: util.appendCustomerURL('/users/create'),
  EditUser: util.appendCustomerURL('/users/edit'),
  Operators: util.appendCustomerURL('/operators'),
  CreateOperator: util.appendCustomerURL('/operators/create'),
  EditOperator: util.appendCustomerURL('/operators/edit'),
  ManageOperatorTemplates: util.appendCustomerURL('/operators/manageTemplates'),
  DeviceInfo: util.appendCustomerURL('/deviceInfo'),
  ViewLicense: util.appendCustomerURL('/viewLicense'),
  DeviceConfig: util.appendCustomerURL('/deviceConfig'),
  ThirdPartyLicense: util.appendCustomerURL('/thirdPartyLicense'),
  Sites: util.appendCustomerURL('/sites'),
  DeviceLogs: util.appendCustomerURL('/deviceLogs'),
  UserPreferences: util.appendCustomerURL('/preferences'),
};

export default Routes;
