const timezone = [
  { value: 'GMT', text: 'GMT - Greenwich Mean Time GMT' },
  { value: 'UTC', text: 'Universal Coordinated Time	GMT' },
  { value: 'ECT', text: 'European Central Time	GMT+1:00' },
  { value: 'EET', text: 'Eastern European Time	GMT+2:00' },
  { value: 'ART', text: '(Arabic) Egypt Standard Time	GMT+2:00' },
  { value: 'EAT', text: 'Eastern African Time	GMT+3:00' },
  { value: 'MET', text: 'Middle East Time	GMT+3:30' },
  { value: 'NET', text: 'Near East Time	GMT+4:00' },
  { value: 'PLT', text: 'Pakistan Lahore Time	GMT+5:00' },
  { value: 'IST', text: 'India Standard Time	GMT+5:30' },
  { value: 'BST', text: 'Bangladesh Standard Time	GMT+6:00' },
  { value: 'VST', text: 'Vietnam Standard Time	GMT+7:00' },
  { value: 'CTT', text: 'China Taiwan Time	GMT+8:00' },
  { value: 'JST', text: 'Japan Standard Time	GMT+9:00' },
  { value: 'ACT', text: 'Australia Central Time	GMT+9:30' },
  { value: 'AET', text: 'Australia Eastern Time	GMT+10:00' },
  { value: 'SST', text: 'Solomon Standard Time	GMT+11:00' },
  { value: 'NST', text: 'New Zealand Standard Time	GMT+12:00' },
  { value: 'MIT', text: 'Midway Islands Time	GMT-11:00' },
  { value: 'HST', text: 'Hawaii Standard Time	GMT-10:00' },
  { value: 'AST', text: 'Alaska Standard Time	GMT-9:00' },
  { value: 'PST', text: 'Pacific Standard Time	GMT-8:00' },
  { value: 'PNT', text: 'Phoenix Standard Time	GMT-7:00' },
  { value: 'MST', text: 'Mountain Standard Time	GMT-7:00' },
  { value: 'CST', text: 'Central Standard Time	GMT-6:00' },
  { value: 'EST', text: 'Eastern Standard Time	GMT-5:00' },
  { value: 'IET', text: 'Indiana Eastern Standard Time	GMT-5:00' },
  { value: 'PRT', text: 'Puerto Rico and US Virgin Islands Time	GMT-4:00' },
  { value: 'CNT', text: 'Canada Newfoundland Time	GMT-3:30' },
  { value: 'AGT', text: 'Argentina Standard Time	GMT-3:00' },
  { value: 'BET', text: 'Brazil Eastern Time	GMT-3:00' },
  { value: 'CAT', text: 'Central African Time' },
];

export default timezone;
