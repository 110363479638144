import React from 'react';
import { Modal, Button } from '@scuf/common';

const AppModal = (props) => (
  <Modal closeIcon onClose={props.handleToggle} open={props.isOpen} size="small">
    <Modal.Content>{props.content}</Modal.Content>
    <Modal.Footer>
      <Button type="primary" size="small" content="No" onClick={props.handleToggle} />
      <Button
        type="primary"
        size="small"
        content="Yes"
        onClick={props.handleSubmit}
        disabled={props.disableSubmit}
      />
    </Modal.Footer>
  </Modal>
);

export default AppModal;
