import React, { Component } from 'react';
import { Table, Grid, Card, Checkbox, Badge, InputLabel, Loader } from '@scuf/common';
import APIClient from '../../common/APIClient';
import { withApplicationContext, withCustomerSiteContext } from '../../common/HOCs';

class ViewLicense extends Component {
  constructor(props) {
    super(props);
    this.state = {
      licenseInfo: [],
      loading: false,
    };
  }

  componentDidMount() {
    const { appContext } = this.props;
    appContext.registerReloadFunction(this.fetchLicenseInfo);
    this.fetchLicenseInfo();
    appContext.setNotification(null);
  }

  componentWillUnmount() {
    const { appContext } = this.props;
    appContext.unregisterReloadFunction();
  }

  fetchLicenseInfo = async (signal) => {
    this.setState({ loading: true });
    let licenseInfo = null;
    try {
      licenseInfo = await APIClient.fetchLicenseInfo(
        this.props.appContext.user.idToken,
        this.props.custSiteContext.selectedCustomer,
        signal
      );
      licenseInfo = licenseInfo.response;
      this.setState({ licenseInfo });
    } catch (err) {
      if (err.name !== 'AbortError') {
        this.props.appContext.handleError(err);
        console.error(err);
      }
    }
    this.setState({ loading: false });
  };

  renderProducts() {
    const { licenseInfo } = this.state;
    return licenseInfo[0].licensedProducts.map((licensedProducts) => {
      return (
        <Card>
          <Card.Header>Product</Card.Header>
          <Card.Content>
            <Table>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>
                    <InputLabel label="Name" />
                  </Table.Cell>
                  <Table.Cell>{licensedProducts.name}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    <InputLabel label="Version" />
                  </Table.Cell>
                  <Table.Cell>{licensedProducts.version}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    <InputLabel label="Limit" />
                  </Table.Cell>
                  <Table.Cell>{licensedProducts.numberOfUsers}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell colSpan="2">
                    <Card>
                      <Card.Header>Features</Card.Header>
                      <Card.Content>
                        <Table>
                          <Table.Body>
                            {Object.keys(licensedProducts.features).map((key) =>
                              licensedProducts.features[key] ? (
                                <Table.Row>
                                  <Table.Cell>
                                    <InputLabel label={key} />
                                  </Table.Cell>
                                  <Table.Cell>
                                    <Checkbox checked={licensedProducts.features[key]} />
                                  </Table.Cell>
                                </Table.Row>
                              ) : null
                            )}
                          </Table.Body>
                        </Table>
                      </Card.Content>
                    </Card>
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Card.Content>
        </Card>
      );
    });
  }

  renderLicenseInfo = () => {
    const { licenseInfo } = this.state;
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column width={12} />
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={10} mWidth={10} sWidth={12}>
            <Card>
              <Card.Header>License Info</Card.Header>
              <Card.Content>
                <Table>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>
                        <InputLabel label="Customer" />
                      </Table.Cell>
                      <Table.Cell>{licenseInfo[0].name}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>
                        <InputLabel label="License Expiration" />
                      </Table.Cell>
                      <Table.Cell>
                        {licenseInfo[0].expiration !== null &&
                        licenseInfo[0].expiration !== undefined
                          ? new Date(licenseInfo[0].expiration).toLocaleDateString()
                          : ''}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>
                        <InputLabel label="Lease Length(Hours)" />
                      </Table.Cell>
                      <Table.Cell>{licenseInfo[0].leaseLengthHours}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>
                        <InputLabel label="Status" />
                      </Table.Cell>
                      <Table.Cell>
                        {licenseInfo[0].suspend ? (
                          <Badge color="red">Suspended</Badge>
                        ) : (
                          <Badge color="green">Active</Badge>
                        )}
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Card.Content>
            </Card>
            {licenseInfo[0].licensedProducts.map((licensedProducts) =>
              this.renderProducts(licensedProducts)
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  };

  render() {
    const { licenseInfo, loading } = this.state;
    if (loading) {
      return (
        <div style={{ position: 'relative', height: '100%' }}>
          <Loader loading={loading} overlayOpacity={0.2} overlayColor="#B2AFAF">
            <div className="placeholder" />
          </Loader>
        </div>
      );
    }
    if (licenseInfo[0]) {
      return this.renderLicenseInfo();
    }
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column width={12} />
        </Grid.Row>
        <Grid.Row>
          <Card>
            <Card.Content>No License Information Exists</Card.Content>
          </Card>
        </Grid.Row>
      </Grid>
    );
  }
}

export default withApplicationContext(withCustomerSiteContext(ViewLicense));
